import { combineComponents } from '@/utils/combineComponents';
import { PushContextProvider } from '@/contexts/PushContext';
import { FeaturesContextProvider } from '@/contexts/FeaturesContext';
import { AuthContextProvider } from '@/contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { PropsWithChildren } from 'react';

const queryClient = new QueryClient();
const QueryProvider = ({ children }: PropsWithChildren) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

const components = [QueryProvider, AuthContextProvider, PushContextProvider, FeaturesContextProvider];

export const AppProviders = combineComponents(...components);
