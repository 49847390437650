import { useCallback, useMemo, useState } from 'react';
import {
	MarketApi,
	Offer,
	SearchGetGradeEnum,
	SearchGetRequest,
	SearchGetSortEnum,
	SearchGetStateEnum,
} from '@smartswap/client-api';
import { config } from '@/configuration';
import { useInfiniteQuery } from 'react-query';

const marketApi = new MarketApi(config);

export const useFeed = () => {
	const [category, setNewCategory] = useState<string>();
	const [query, setQuery] = useState<string>();
	const [warehouse, setWarehouse] = useState<string>();
	const [state, setState] = useState<SearchGetStateEnum>(SearchGetStateEnum.All);
	const [country, setCountry] = useState<string | undefined>();
	const [grade, setGrade] = useState<SearchGetGradeEnum | undefined>();
	const [sort, setSort] = useState<SearchGetSortEnum>(SearchGetSortEnum.IdDesc);

	const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
		['offers', query, category, warehouse, state, grade, country, sort],
		({ pageParam }) => {
			const searchRequest: SearchGetRequest = {
				category,
				text: query,
				warehouse,
				state,
				country,
				grade,
				sort
			};

			if (pageParam) {
				searchRequest.cursor = pageParam;
			}

			return marketApi.searchGet(searchRequest);
		},
		{
			getNextPageParam: (lastPage) => lastPage.paging?.nextCursor,
		},
	);

	const setCategory = useCallback(
		(newCategory: string) => {
			if (category !== newCategory) {
				setNewCategory(newCategory);
			}
		},
		[category],
	);

	const offers = useMemo(
		() =>
			data?.pages.reduce<Offer[]>((acc, current) => {
				return [...acc, ...(current.offers || [])];
			}, []) || [],
		[data?.pages],
	);

	return {
		offers,
		setQuery,
		setCategory,
		state,
		setState,
		country,
		setCountry,
		grade,
		setGrade,
		setWarehouse,
		sort,
		setSort,
		loadMore: () => fetchNextPage(),
		hasMore: hasNextPage,
	};
};
