import * as React from 'react';
import styled from 'styled-components';
import { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { Card } from '@/components/Card/Card';
import { useOnClickOutside } from '@/hooks/useClickOutside';

interface DropdownProps {
	content: ReactNode;
	className?: string;
}

export const DropdownContent = styled(Card)`
	position: absolute;
	top: 125%;
	right: 0;
	z-index: 2;
	cursor: pointer;
`;

const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	cursor: pointer;
	user-select: none;
	outline: none;
`;

export const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = ({ className, children, content }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState<boolean>(false);

	useOnClickOutside(ref, () => {
		setVisible(false);
	});

	function toggleDropdown(e: React.MouseEvent) {
		e.preventDefault();
		setVisible(v => !v);
	}

	return (
		<Wrapper ref={ref} className={className} tabIndex={0} onClick={toggleDropdown}>
			{children}
			{visible && <DropdownContent>{content}</DropdownContent>}
		</Wrapper>
	);
};
