import styled from 'styled-components';
import { Card, CardContent } from '@/components/Card/Card';
import { Button } from '@/components/Button';
import { InputField, Label } from '@/components/Input';

export const GridWrapper = styled.div``;
export const Grid = styled.div`
	display: grid;
	grid-gap: 24px;
	font-family: 'InterUI';
`;
export const Section = styled(Card)`
	width: 100%;
`;
export const InnerCardContent = styled(CardContent)`
	padding: 20px;
`;
export const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const Title = styled.h3`
	font-weight: normal;
	font-size: 18px;
	margin: 0.2rem 0 1rem;
`;
export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${Button} {
        margin-left: 1em;
    }
`;
export const FormGrid = styled.div`
	display: grid;
	grid-row-gap: 1em;
    
    ${Label} {
        margin: 0;
    }
`;
