import React from 'react';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';
import { useTranslation } from 'react-i18next';

const features = [
	{
		title: 'saveTheWorld',
		content: 'saveTheWorldContent',
		imageSrc: '/images/Rectangle.png',
	},
	{
		title: 'hassleFree',
		content: 'hassleFreeContent',
		imageSrc: '/images/Rectangle-1.png',
	},
	{
		title: 'freeOfCharge',
		content: 'freeOfChargeContent',
		imageSrc: '/images/Rectangle-2.png',
	},
];

const FeatureImage = styled.img`
	@media screen and ${screenSize.mobile} {
		width: 100%;
		height: 128px;
		object-fit: cover;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
`;

const FeatureContent = styled.div`
	position: absolute;
	padding: 48px;
	box-sizing: border-box;
	background-color: var(--color-default);
	border-radius: 8px;
	box-shadow: 0 16px 32px rgba(67, 86, 100, 0.12);
	width: 83%;
	max-width: 644px;

	font-family: InterUI;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 2;
	color: #72809d;

	@media screen and ${screenSize.mobile} {
		line-height: 1.86;
		padding: 24px;
	}

	h3 {
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 1.5;
		letter-spacing: -0.4px;
		color: #35425b;
		margin-top: 0;

		@media screen and ${screenSize.mobile} {
			margin-bottom: 8px;
		}
	}

	@media screen and ${screenSize.mobile} {
		width: 100%;
		position: relative;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
`;

const FeatureRow = styled.div`
	margin: 48px 0;
	display: flex;
	flex-flow: row;
	align-items: center;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	&:nth-child(odd) {
		justify-content: flex-start;

		${FeatureContent} {
			right: 0;
		}
	}

	&:nth-child(even) {
		justify-content: flex-end;

		${FeatureContent} {
			left: 0;
		}
	}

	@media screen and ${screenSize.mobile} {
		flex-flow: column;
		margin: 16px 0;
	}
`;

export const FeatureList = () => {
	const { t } = useTranslation('homepage');

	return (
		<>
			{features.map((feature) => (
				<FeatureRow key={feature.imageSrc}>
					<FeatureImage src={feature.imageSrc} />
					<FeatureContent>
						<h3>{t(feature.title)}</h3>
						{t(feature.content)}
					</FeatureContent>
				</FeatureRow>
			))}
		</>
	);
};
