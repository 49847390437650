import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const ContentArea = styled.div`
	width: 100%;
	max-width: 730px;

	font-family: InterUI, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #778699;
	margin-bottom: 80px;

	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;

		text-align: center;
		letter-spacing: -0.4px;
		color: #2c3f58;
		margin-bottom: 32px;
		margin-top: 64px;
	}

	h2 {
		font-family: InterUI, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #2c3f58;
		margin-bottom: 24px;
	}
`;

const Content = styled.div`
	margin: 0 32px;
	text-align: center;
`;

export const CampaignPage: React.FC = () => {
	const { t } = useTranslation('minimalism');

	return (
		<Wrapper>
			<ContentArea>
				<h1 dangerouslySetInnerHTML={{ __html: t('pageTitle') }} />
				<Content dangerouslySetInnerHTML={{ __html: t('pageContent') }} />
			</ContentArea>
		</Wrapper>
	);
};
