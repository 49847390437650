import { Reminder } from '@smartswap/client-api';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ackReminders, getAllReminders } from '@/services/RemindersService';
import useAsyncEffect from 'use-async-effect';
import { partition } from 'lodash';
import { PushContext } from '@/contexts/PushContext';

interface RemindersContextProps {
	chatReminders: Reminder[];
	transactionReminders: Reminder[];
	nonAckedChats: number;
	nonAckedTransactions: number;
	acknowledgeChats: (chatId: string) => void;
	getRemindersForChat: (chatId: string) => Reminder[];
	reload: () => void;
	acknowledgeTransactions: () => void;
	acknowledgeReminders: (ids: string[]) => void;
}

export const RemindersContext = createContext<RemindersContextProps>({} as RemindersContextProps);

export const RemindersProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [reminders, setReminders] = useState<Reminder[]>([]);
	const { addHandler, removeHandler } = useContext(PushContext);

	const reload = useCallback(async () => {
		setReminders(await getAllReminders());
	},[]);

	const [chatReminders, transactionReminders] = useMemo(
		() => partition(reminders, (r) => r.type === 'direct_message'),
		[reminders]
	);

	const getRemindersForChat = useCallback((chatId: string)  => {
		return chatReminders.filter((reminder) => reminder.datum?.directMessage?.chatId === chatId);
	},[chatReminders]);

	const acknowledgeReminders = useCallback(async (ids: string[]) => {
		if (ids.length === 0) return;
		await ackReminders(ids);
		await reload();
	}, [reload]);


	const acknowledgeChats = useCallback(async (chatId: string) => {
		const ackIds = getRemindersForChat(chatId).map((r) => r.id);
		await acknowledgeReminders(ackIds);
	}, [acknowledgeReminders, getRemindersForChat]);



	const acknowledgeTransactions = useCallback( async () => {
		const ackIds = transactionReminders.map((r) => r.id);
		await acknowledgeReminders(ackIds);
	}, [acknowledgeReminders, transactionReminders]);

	const nonAckedChats = useMemo(() => chatReminders.filter((reminder) => !reminder.isAcked).length, [chatReminders]);

	const nonAckedTransactions = useMemo(
		() => transactionReminders.filter((reminder) => !reminder.isAcked).length,
		[transactionReminders],
	);

	useAsyncEffect(reload, []);
	useEffect(() => {
		addHandler('ReminderCreated', reload);
		return () => removeHandler('ReminderCreated', reload);
	}, [addHandler, reload, removeHandler]);
	// useEffect(() => addHandler('ReminderDismissed', reload), [addHandler, reload]);

	return (
		<RemindersContext.Provider
			value={{
				reload,
				chatReminders,
				transactionReminders,
				nonAckedChats,
				nonAckedTransactions,
				acknowledgeChats,
				getRemindersForChat,
				acknowledgeTransactions,
				acknowledgeReminders,
			}}
		>
			{children}
		</RemindersContext.Provider>
	);
};
