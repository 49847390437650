import React, { useCallback, useState } from 'react';
import { useDialog } from '@/contexts/DialogContext';
import { Dialog } from './Dialog';
import styled from 'styled-components';
import { InputField } from './Input';
import { Button } from './Button';
import { Formik } from 'formik';
import { GetStripeConfigResponse, PaymentApi, PersonalApi, Profile } from '@smartswap/client-api';
import { config } from '@/configuration';
import useAsyncEffect from 'use-async-effect';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Appearance, loadStripe, Stripe, StripeElementLocale, StripeError } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

const Wrapper = styled.div`
	font-family: 'InterUI', sans-serif;
	font-weight: normal;
`;

const Row = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 1em;
	}

	& > * {
		flex: 1;

		&:not(:first-child) {
			margin-left: 16px;
		}
	}

	label {
		margin: 0;
	}
`;

const paymentApi = new PaymentApi(config);
const personalApi = new PersonalApi(config);

interface FormFields {
	nameOnCard: string;
	postalCode: string;
	billingAddress: string;
	city: string;
}

type FormProps = {
	onCancel?: () => void;
	onSuccess?: () => void;
	onError?: (message: string) => void;
	intentKey: string;
};

const useCardDetailsValidation = () => {
	const { t } = useTranslation('card-dialog');

	return Yup.object().shape({
		nameOnCard: Yup.string()
			.min(2, t('validation.characterCountRequired', { count: 2 }))
			.max(50, t('validation.characterCountMax', { count: 50 }))
			.required(t('validation.isRequired', { name: 'nameOnCard' })),
		billingAddress: Yup.string()
			.min(2, t('validation.characterCountRequired', { count: 2 }))
			.max(50, t('validation.characterCountMax', { count: 50 }))
			.required(t('validation.isRequired', { name: 'billingAddress' })),
		city: Yup.string()
			.min(2, t('validation.characterCountRequired', { count: 2 }))
			.max(50, t('validation.characterCountMax', { count: 50 }))
			.required(t('validation.isRequired', { name: 'city' })),
		postalCode: Yup.string()
			.min(2, t('validation.characterCountRequired', { count: 2 }))
			.max(50, t('validation.characterCountMax', { count: 50 }))
			.required(t('validation.isRequired', { name: 'postalCode' })),
	});
};

const useStripeSettings = () => {
	const [stripeConfig, setStripeConfig] = useState<GetStripeConfigResponse | null>(null);
	const [stripe, setStripe] = useState<Stripe | null>(null);

	useAsyncEffect(async () => {
		const config = await paymentApi.stripeConfigGet();

		if (!config) {
			throw Error('Failed to load payment information');
		}

		setStripeConfig(config);
		const newStripe = await loadStripe(config.publicKey);
		setStripe(newStripe);
	}, []);

	return { stripe, stripeConfig };
};

interface BillingDetailsProps {
	onSuccess?: () => void;
	onCancel?: () => void;
	onError?: (message: string) => void;
}

const BillingDetailsForm: React.FC<FormProps> = ({ onCancel, onSuccess, intentKey, onError }) => {
	const [profile, setProfile] = useState<Profile | null>(null);
	const { t } = useTranslation('card-dialog');
	const stripe = useStripe();
	const elements = useElements();
	const formFieldsValidation = useCardDetailsValidation();

	useAsyncEffect(async () => {
		const profile = await personalApi.myProfileGet();
		setProfile(profile);
	}, []);

	function handleError(error: StripeError) {
		console.error('stripe error', error.message);
	}

	async function onSubmit(form: FormFields) {
		if (!stripe || !elements) {
			return null;
		}

		const { error: submitError } = await elements.submit();

		if (submitError) {
			handleError(submitError);
			return;
		}

		const paymentMethod = await stripe
			?.createPaymentMethod({
				elements,
				params: {
					billing_details: {
						address: {
							city: form.city,
							line1: form.billingAddress,
							postal_code: form.postalCode,
						},
						email: profile?.email,
						name: form.nameOnCard,
					},
				},
			});

		const id = paymentMethod?.paymentMethod?.id;
		if (!id) {
			onError?.('Failed to create payment method.');
			return;
		}

		await paymentApi
			.stripeConfirmCardPost({
				confirmStripeCardRequest: {
					paymentMethodId: id,
				},
			})
			.then(() => {
				onSuccess?.();
			})
			.catch(async (res) => {
				const errorMessage = await res.json();
				onError?.(errorMessage.error);
			});
	}

	return (
		<Formik
			validateOnBlur={true}
			validateOnChange={false}
			onSubmit={onSubmit}
			validationSchema={formFieldsValidation}
			initialValues={{
				nameOnCard: profile?.fullName || '',
				postalCode: '',
				billingAddress: '',
				city: '',
			}}
		>
			{(formik) => {
				return (
					<form onSubmit={formik.handleSubmit}>
						<Row>
							<InputField type={'text'} label={t('form.nameOnCard')} name={'nameOnCard'} />
						</Row>
						<Row>
							<InputField type={'text'} label={t('form.billingAddress')} name={'billingAddress'} />
						</Row>
						<Row>
							<InputField type={'text'} label={t('form.city')} name={'city'} />
						</Row>
						<Row>
							<InputField type={'text'} label={t('form.postalCode')} name={'postalCode'} />
						</Row>
						<PaymentElement />
						<Row>
							<p
								style={{ fontSize: '.75em', margin: 0 }}
								dangerouslySetInnerHTML={{ __html: t('form.cardDisclaimer') }}
							/>
						</Row>
						<Row>
							<Button
								type={'submit'}
								disabled={formik.isSubmitting || !stripe || !elements}
								data-secret={intentKey}
							>
								{t('form.addCard')}
							</Button>
							<Button $variant={'outline'} type={'button'} onClick={onCancel}>
								{t('form.cancel')}
							</Button>
						</Row>
					</form>
				);
			}}
		</Formik>
	);
};

type AddCardDialogProps = {
	onComplete?: () => void;
};

const appearance: Appearance = {
	variables: {
		colorBackground: '#f0f4f8',
		colorPrimaryText: '#2C3F58',
		fontSizeBase: '14px',
		fontSizeSm: '14px',
		borderRadius: '5px',
		colorText: '#778699',
		spacingUnit: '3px',
		colorDanger: '#F65F43',
		colorDangerText: '#F65F43',
	},
	rules: {
		'.Label': {
			color: '#2C3F58',
			margin: '7px 0',
			fontSize: '12px',
		},
		'.Input--invalid': {
			boxShadow: 'none',
			// borderSize: '1px',
		},
		'.TermsText': {
			fontSize: '0px',
		},
	},
};

const BillingDetails = ({ onSuccess, onCancel, onError }: BillingDetailsProps) => {
	const { stripe: stripeLoader, stripeConfig } = useStripeSettings();
	const { i18n } = useTranslation();

	return (
		<Elements
			stripe={stripeLoader}
			options={{
				mode: 'setup',
				currency: 'eur',
				locale: i18n.language as StripeElementLocale,
				paymentMethodCreation: 'manual',
				appearance,
			}}
		>
			<BillingDetailsForm
				intentKey={stripeConfig?.setupIntentKey || ''}
				onCancel={onCancel}
				onSuccess={onSuccess}
				onError={onError}
			/>
		</Elements>
	);
};

export const AddCardDialog: React.FC<AddCardDialogProps> = (props) => {
	const { popDialog, showDialog } = useDialog();
	const { t } = useTranslation(['card-dialog', 'errors']);

	const handleError = useCallback(
		(message: string) => {
			showDialog(<Dialog title={t('Failed to process payment method')}>{
				t(message)
			}</Dialog>);
		},
		[showDialog, t],
	);

	return (
		<Dialog title={t('addCardInformation')}>
			<Wrapper>
				<BillingDetails onSuccess={props.onComplete} onCancel={popDialog} onError={handleError} />
			</Wrapper>
		</Dialog>
	);
};
