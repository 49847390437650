import { Chat, ChatMessage, Offer } from '@smartswap/client-api';
import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import { Send } from 'react-feather';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getChats, getMessages, sendMessage } from '@/services/ChatService';
import { MessageList } from './MessageList';
import { Input } from '../Input';
import { FormikHelpers, useFormik } from 'formik';
import { MemberContext } from '@/contexts/MemberContext';
import { screenSize } from '@/ScreenSize';
import { LeftIcon } from '@/Icons';
import { getUserId } from '@/services/UserService';
import { getParticipantNames } from '@/lib/chat';
import { Button } from '@/components/Button';
import { RemindersContext } from '@/components/RemindersContext';
import { PushContext } from '@/contexts/PushContext';
import { OfferMessageBubble } from './OfferMessageBubble';
import { useTranslation } from 'react-i18next';
import { findLast } from 'lodash';

const Wrapper = styled.div`
	padding: 0;
	height: calc(100vh - 60px);
	display: flex;
	flex-flow: column;
	overflow-x: hidden;

	@media screen and ${screenSize.sm} {
	}
`;

interface MessageViewProps {
	chatId?: string;
}

const MessageInput = styled.div`
	background-color: var(--color-default);
	height: 75px;
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 0 16px;

	form {
		display: flex;
		flex-flow: row;

		input {
			flex: 1;
		}

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 8px;
		}
	}
`;

interface FormMessage {
	message: string;
}

const MessageCard = styled(OfferMessageBubble)`
	width: 100%;
	margin: 0;
	max-width: 100%;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
`;

const MessageInputWrapper = styled.div`
	display: flex;
	flex-flow: column;
`;

const UserBar = styled.div`
	background-color: var(--color-default);

	a {
		height: 60px;
		display: flex;
		flex-flow: row;
		align-items: center;

		& > * {
			margin-left: 16px;
		}
	}

	@media screen and ${screenSize.sm} {
		display: none;
	}
`;

function useLocationChat() {
	const { chatId } = useParams<{ chatId: string }>();

	// UserList and from Offer page
	const { state } = useLocation();
	const [chat, setChat] = useState<Chat | null>(state.chat);

	useEffect(() => {
		getChats().then(({ chats }) => {
			const chatFound = chats?.find((c) => c.id === chatId);
			setChat(chatFound || null);
		});
	}, [chatId]);

	const activeChatId = useMemo(() => {
		return chat?.id || chatId;
	}, [chat?.id, chatId]);

	return { chat, chatId: activeChatId };
}

export const MessageView: React.FC<MessageViewProps> = () => {
	const member = useContext(MemberContext);
	const [messages, setMessages] = useState<ChatMessage[]>();
	const { chat, chatId } = useLocationChat();
	const { state } = useLocation();

	// From Offer page
	const offerFromNavigation: Offer | null = state.offer;

	const userId = getUserId();
	const chatNames = getParticipantNames(chat, userId);
	const { acknowledgeChats } = useContext(RemindersContext);
	const { addHandler, removeHandler } = useContext(PushContext);
	const { t } = useTranslation('messages');

	const lastOfferInMessagesIsCurrentOffer = useMemo(() => {
		const lastMessageWithOffer = findLast(messages, (message) => !!message.offerId);
		return lastMessageWithOffer?.offerId === offerFromNavigation?.id;
	}, [offerFromNavigation, messages]);

	const formik = useFormik<FormMessage>({
		onSubmit: function ({ message: text }: FormMessage, helpers: FormikHelpers<FormMessage>) {
			if (!chatId) {
				console.error('Chat doe not exist: ', chatId);
				return;
			}

			// Used only for optimistic update
			const newMessage: ChatMessage = {
				author: {
					id: userId || '',
					country: member.country || '',
					displayName: member.displayName || '',
					location: member.location || '',
				},
				text,
				time: new Date(),
				offerId: !lastOfferInMessagesIsCurrentOffer ? offerFromNavigation?.id : undefined,
			};

			// Optimistic update
			setMessages([...(messages || []), newMessage]);

			// TODO: check if message is sent
			// Send offer only with the initial message after opening chat from the offer view
			sendMessage(chatId, text, !lastOfferInMessagesIsCurrentOffer ? offerFromNavigation?.id : undefined);

			helpers.resetForm();
		},
		initialValues: {
			message: '',
		},
	});

	const updateMessages = useCallback(() => {
		if (!chatId) return;
		getMessages(chatId).then((response) => {
			const reversedMessages = response.messages?.slice()?.reverse();
			setMessages(reversedMessages || []);
		});

		acknowledgeChats(chatId);
	}, [acknowledgeChats, chatId]);

	useEffect(updateMessages, [updateMessages]);
	useEffect(() => {
		addHandler('ChatMessageCreated', updateMessages);
		return () => removeHandler('ChatMessageCreated', updateMessages);
	}, [addHandler, removeHandler, updateMessages]);

	const { handleChange, handleSubmit, values } = formik;
	return (
		<Wrapper>
			<UserBar>
				<Link to={'/messages'}>
					<LeftIcon />
					<Avatar size={'34px'} round={'1000px'} name={chatNames || ''} />
					<span>{chatNames || ''}</span>
				</Link>
			</UserBar>

			<MessageList messages={messages} />

			<MessageInputWrapper>
				{!lastOfferInMessagesIsCurrentOffer && offerFromNavigation?.id && (
					<MessageCard offerId={offerFromNavigation?.id} />
				)}
				<MessageInput>
					<form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Input
							name={'message'}
							placeholder={t('typeYourMessage')}
							onChange={handleChange}
							value={values.message}
							autoComplete={'off'}
						/>
						<Button type={'submit'}>
							<Send />
						</Button>
					</form>
				</MessageInput>
			</MessageInputWrapper>
		</Wrapper>
	);
};
