import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmail } from '@/services/PersonalService';

export const VerifyEmailPage = () => {
	const { code } = useParams();
	const navigate = useNavigate();

	// TODO: feedback on fail
	verifyEmail(code || '').then(() => {
		navigate('/offers');
	});

	return null;
};
