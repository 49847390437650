import * as React from 'react';
import { Card, PaymentApi } from '@smartswap/client-api';
import styled from 'styled-components';
import { CloseIcon } from '@/Icons';
import { screenSize } from '@/ScreenSize';
import { config } from '@/configuration';

type PaymentMethodProps = {
	card: Card;
	onRemove?: () => void;
};

const BrandImage = styled.img`
	flex: 0 0 auto;
`;

const LeftSpan = styled.span`
	padding: 0 1em;
	flex: 0 0 auto;

	@media screen and ${screenSize.mobile} {
		padding: 0 5px;
		font-size: 0.8em;
	}
`;

const Close = styled(CloseIcon)`
	cursor: pointer;
	margin-right: auto;
`;

const CardRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 3em;
`;

export const PaymentMethod: React.FC<PaymentMethodProps> = (props) => {
	const api = new PaymentApi(config);
	const card = props.card;
	const logo = `/iconss/paymentmethod/${card.brand}.svg`;

	async function onRemove() {
		await api.myCardsIdDelete({ id: card.id });
		props.onRemove && props.onRemove();
	}

	return (
		<CardRow>
			<BrandImage src={logo} alt={card.brand} />
			<LeftSpan>···· ···· ···· {card.finalDigits}</LeftSpan>
			<LeftSpan>{card.nameOnCard}</LeftSpan>
			<LeftSpan>{card.expiryDate}</LeftSpan>
			<Close onClick={onRemove} />
		</CardRow>
	);
};
