import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { GiveawayItemsList } from '@/components/GiveawayItems/GiveawayItemsList';
import { FilterTabs } from '@/components/FilterTabs/FilterTabs';

export const GiveawayItemsPage: React.FC = () => {
	const { t } = useTranslation('account');

	return (
		<Fragment>
			<h2>{t('itemsTrades')}</h2>
			<FilterTabs />
			<GiveawayItemsList />
		</Fragment>
	);
};
