import { Dropdown, DropdownContent } from '@/components/Dropdown';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';

export const RemindersDropdown = styled(Dropdown)`
	${DropdownContent} {
		max-width: 300px;
		max-height: 400px;
		background: var(--color-default);
		overflow-y: auto;

		font-family: InterUI;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}

	@media screen and ${screenSize.mobile} {
		position: static;

		${DropdownContent} {
			max-width: 100%;
			width: 100%;
			left: 0;
			right: 0;
			top: 60px;
		}
	}
`;
