import React, { useState } from 'react';
import { SelfContainedInput } from './Forms/SelfContainedInput';
import { updatePhone, verifyPhoneCode } from '@/services/PersonalService';
import { UpdatePhoneVerification } from './UpdatePhoneVerification';
import { useTranslation } from 'react-i18next';

interface PhoneVerifyWizardProps {
	onSuccess?: () => void;
	onError?: () => void;
}

export const PhoneVerifyWizard: React.FC<PhoneVerifyWizardProps> = ({ onSuccess }) => {
	const [number, setNumber] = useState<string | null>(null);
	const [error, setError] = useState<string>('');
	const { t } = useTranslation('account');

	function submitNewPhone(newPhone: string) {
		updatePhone(newPhone)
			.then(() => {
				setNumber(newPhone);
				setError('');
			})
			.catch((e) => {
				if (e.status === 400) {
					setError(t('addPhoneDialog.validation.validPhoneRequired'));
				}
			});
	}

	function submitVerificationCode(verificationCode: string) {
		verifyPhoneCode(verificationCode)
			.then(() => {
				onSuccess?.();
				setError('');
			})
			.catch((e) => {
				if (e.status === 400) {
					setError(t('addPhoneDialog.validation.validVerificationCodeRequired'));
				}
			});
	}

	return (
		<div>
			{!number && (
				<>
					<SelfContainedInput
						label={t('addPhoneDialog.mobilePhone')}
						name="phone"
						type={'phone'}
						initialValue={''}
						onSubmit={submitNewPhone}
						error={error}
					/>
				</>
			)}

			{number && <UpdatePhoneVerification phoneNumber={number} onSubmit={submitVerificationCode} error={error} />}
		</div>
	);
};
