import {
	CreateOfferRequest,
	MarketApi,
	Offer,
	OffersIdPublishPutRequest,
} from '@smartswap/client-api';
import { config, getUserFromStorage } from '@/configuration';

const marketApi = new MarketApi(config);

export const publishOffer = async (offer: Offer) => {
	if (!offer || !offer.id) {
		return;
	}

	const requestParameters: OffersIdPublishPutRequest = {
		id: offer.id,
	};

	return await marketApi.offersIdPublishPut(requestParameters).catch((reason) => console.error(reason));
};

export const createOffer = (request: CreateOfferRequest) => {
	// Dimensions
	const { addressId, ...clean } = request;
	const offerRequest = request.enclosureSlug === 'oversize' ? request : clean;
	return marketApi.offersPost({ createOfferRequest: offerRequest }).then((offer) => {
		publishOffer(offer);
		return offer;
	});
};

export async function updateOffer(id: string, createOfferRequest: CreateOfferRequest) {
	return marketApi.offersIdDetailsPutRaw({ id , createOfferRequest});
}

export async function updateOfferUploads(id: string, uploadUuids: string[]) {
	const apiKey = getUserFromStorage()?.accessToken;

	return await fetch(config.basePath + `/offers/${id}/uploads`, {
		body: JSON.stringify({ uuids: uploadUuids }),
		method: 'PUT',

		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + apiKey,
		},
	});
}

export function getOffer(offerId: string) {
	return marketApi.offersIdGet({ id: offerId });
}

export function getOfferChat(offerId: string) {
	return marketApi.offersIdChatGet({ id: offerId });
}

export async function unpublishOffer(offer: Offer) {
	await marketApi.offersIdUnpublishPut({ id: offer.id });
}
