import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dropdown } from './Dropdown';

const Popup = styled.div`
	color: #2c3f58;
	font-size: 14px;
	font-weight: 300;
`;

const ListItem = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 8px 16px;
	box-sizing: border-box;

	&:hover {
		background-color: rgba(240, 244, 248, 1);
	}
`;

const languageFull: Record<string, string> = {
	en: 'English',
	et: 'Eesti keel',
	fi: 'Suomi',
	ru: 'Русский',
};

const LocalePopup: FC = () => {
	const { i18n } = useTranslation();
	const languages = ['et', 'en', 'ru', 'fi'];

	return (
		<Popup>
			{languages.map((languageCode) => {
				return (
					<ListItem key={languageCode} onClick={() => i18n.changeLanguage(languageCode)}>
						{languageFull[languageCode]}
					</ListItem>
				);
			})}
		</Popup>
	);
};

export const LocaleChanger: React.FC = () => {
	const { i18n } = useTranslation();

	return <Dropdown content={<LocalePopup />}>{i18n.language.toUpperCase()}</Dropdown>;
};
