import React from 'react';
import styled from 'styled-components';
import { screenSize } from '@/ScreenSize';

const Wrapper = styled.div`
	width: 100%;
	max-width: 600px;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin: 192px 0;

	& > div {
		margin: 0 8px;
	}

	img {
		max-width: 100%;
		height: auto;
		object-fit: cover;
	}

	@media screen and ${screenSize.mobile} {
		margin: 128px 0;
	}
`;

const logos = [
	{
		imgSrc: '/images/Itella_sinine.png',
		alt: 'Itella post',
		href: 'https://www.itella.ee/',
	},
	{
		imgSrc: '/images/lets-do-it.png',
		alt: "Let's do it world",
		href: 'https://www.letsdoitworld.org/',
	},
	{
		imgSrc: '/images/logo-wcd-no-date.png',
		alt: 'World ceanup day',
		href: 'https://www.worldcleanupday.org',
	},
];

export const HomepageLogos = () => {
	return (
		<Wrapper>
			{logos.map((logo) => (
				<div key={logo.imgSrc}>
					<a href={logo.href} target="_blank" rel="noopener noreferrer">
						<img src={logo.imgSrc} alt={logo.alt} />
					</a>
				</div>
			))}
		</Wrapper>
	);
};
