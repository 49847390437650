import React, { useContext, useState } from 'react';
import { DialogContext } from '@/contexts/DialogContext';
import { Dialog } from './Dialog';
import styled from 'styled-components';
import { UpdatePhoneVerification } from './UpdatePhoneVerification';
import { useTranslation } from 'react-i18next';
import { verifyPhoneCode } from '@/services/PersonalService';

const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	max-width: 400px;
	font-face: 'InterUI', sans-serif;
	font-weight: normal;
`;

interface UpdatePhoneDialogProps {
	number: string;
	onSubmit: (code: string) => Promise<void>;
}

export const UpdatePhoneDialog: React.FC<UpdatePhoneDialogProps> = ({ number, onSubmit }) => {
	const { popDialog } = useContext(DialogContext);
	const [error, setError] = useState<string>('');
	const { t } = useTranslation('account');

	function submitVerificationCode(verificationCode: string) {
		verifyPhoneCode(verificationCode)
			.then(() => {
				popDialog?.();
				setError('');
			})
			.catch((e) => {
				if (e.status === 400) {
					setError(t('addPhoneDialog.validation.validVerificationCodeRequired'));
				}
			});
	}

	return (
		<Dialog title={t('addPhoneDialog.title')}>
			<Wrapper>
				<UpdatePhoneVerification error={error} phoneNumber={number} onSubmit={submitVerificationCode} />
			</Wrapper>
		</Dialog>
	);
};
