import { config } from '@/configuration';
import {
	AuthApi,
	AuthLoginPostRequest,
	AuthLoginRequestClientIdEnum,
	AuthLoginRequestGrantTypeEnum,
	AuthLoginResponse,
} from '@smartswap/client-api';

export async function login(username: string, password: string): Promise<AuthLoginResponse> {
	const requestParameters: AuthLoginPostRequest = {
		authLoginRequest: {
			clientId: AuthLoginRequestClientIdEnum.Web,
			grantType: AuthLoginRequestGrantTypeEnum.Password,
			password,
			username,
		},
	};

	const api = new AuthApi(config);
	return await api.authLoginPost(requestParameters);
}
