import styled from 'styled-components';
import { Button } from '@/components/Button';

export const FormInputWrapper = styled.div`
    display: flex;

    ${Button} {
        margin-left: 1em;
    }
`;
