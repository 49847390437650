import { PointsPurchaseApi } from '@smartswap/client-api';
import { config } from '@/configuration';

const pointPurcahseApi = new PointsPurchaseApi(config);

export async function getPointPurchaseQuote(amount: number) {
	return pointPurcahseApi.pointsPurchaseQuotesPost({
		generatePointsPurchaseQuotesRequest: {
			amount,
		},
	});
}

export async function purchasePoint(barcode: string) {
	return pointPurcahseApi.pointsPurchasePurchasePost({
		purchasePointsRequest: {
			barcode,
		},
	});
}
