import { parseInline } from 'marked';
import { PostProcessorModule } from 'i18next';

interface ProcessorOptions {
	mdKeys?: string[];
}

export function createProcessor(options?: ProcessorOptions): PostProcessorModule {
	return {
		name: 'md',
		type: 'postProcessor',
		process(value, key, _, translator): string {
			// console.log(translator);
			// const lastKey = key[0]?.split('.').pop();
			// const isMarkdown = lastKey && options?.mdKeys?.includes(lastKey);
			// return isMarkdown ? parse(value, { breaks: true }) : value;

			// @ts-expect-error types are wrong
			return parseInline(value, { breaks: true });
		},
	};
}
