import styled from 'styled-components';
import * as React from 'react';
import { Offer, QuoteTypeEnum } from '@smartswap/client-api';
import { formatCurrency } from '@/lib/currency';
import { useTranslation } from 'react-i18next';

const Light = styled.span`
	font-size: 14px;
	color: var(--offer-quote-color);

	strong {
		color: var(--offer-quote-color-strong);
		font-weight: normal;
	}
`;

const QuoteWrapper = styled.div`
	font-family: InterUI, sans-serif;
	font-style: normal;
	font-weight: normal;
	text-align: center;
	margin-bottom: var(--step);

	.no-wrap {
		white-space: nowrap;
	}
`;

export const Quote: React.FC<{ offer: Offer }> = ({ offer }) => {
	const { t } = useTranslation('checkout');
	const quotes = offer?.quotes?.filter((q) => q.type !== QuoteTypeEnum.Pickup); // TODO: Remove for pickup

	let text: string;

	//TODO: refactor string concatenation into components
	if (!quotes) {
		text = 'No delivery options available';
	} else {
		text = `${t('delivery')}: `;

		const courierQuotes = quotes.filter((quote) => quote.type !== QuoteTypeEnum.Inperson);
		text += courierQuotes
			.map((quote) => {
				const courierName = t(`couriers.${quote.courierName || ''}`);
				return `<strong>${courierName}</strong> <span class="no-wrap">${formatCurrency(
					quote.shipping || 0,
				)} €</span>`;
			})
			.join(', ');

		const inPerson = quotes.find((quote) => quote.type === QuoteTypeEnum.Inperson);

		if (inPerson) {
			if (courierQuotes.length) {
				text += t('orInPerson');
			} else {
				text = t('onlyInPerson');
			}
			text += ' <span class="no-wrap">0 €</span>';
		}
	}

	return (
		<QuoteWrapper>
			<Light dangerouslySetInnerHTML={{ __html: text }} />
		</QuoteWrapper>
	);
};
