import styled from 'styled-components';

const colorSchemes = {
	success: 'var(--color-success)',
	primary: 'var(--color-primary)',
	neutral: 'var(--badge-color-neutral)',
	danger: 'var(--badge-color-danger)',
};

interface BadgeProps {
	$colorScheme?: keyof typeof colorSchemes;
}

export const Badge = styled.div<BadgeProps>`
	background-color: ${(props) => (props.$colorScheme ? colorSchemes[props.$colorScheme] : colorSchemes.neutral)};
	font-family: InterUI;
	padding: 4px 6px;
	font-size: 10px;
	color: var(--color-default);
	border-radius: 4px;
	text-transform: uppercase;
	font-weight: 200;
`;
