import React, { useContext, useState } from 'react';
import { Dialog } from '@/components/Dialog';
import { Button } from '@/components/Button';
import { getMyCards } from '@/services/PaymentService';
import { Card, PointsPurchaseQuote } from '@smartswap/client-api';
import styled from 'styled-components';
import { getPointPurchaseQuote, purchasePoint } from '@/services/PointPurchase';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/lib/currency';
import { useMountEffect } from '@/hooks/useMountEffect';
import { DialogContext } from '@/contexts/DialogContext';
import { AddCardDialog } from '@/components/AddCardDialog';
import { RecommendFriendDialog } from '@/components/RecommendFriendDialog';

const StyledDialog = styled(Dialog)`
	${Button} {
		margin-top: 8px;
	}
`;

interface PointSaleWizardProps {
	onSuccess?: () => void;
}

export const PointSaleWizard: React.FC<PointSaleWizardProps> = ({ onSuccess }) => {
	const [myCards, setMyCards] = useState<Card[]>();
	const [quotes, setQuotes] = useState<PointsPurchaseQuote[]>();
	const { showDialog, popDialog } = useContext(DialogContext);
	const [purchaseSuccess, setPurchaseSuccess] = useState(false);

	const { t } = useTranslation('point-purchase');

	useMountEffect(() => {
		refreshCards();
		getPointPurchaseQuote(1).then((r) => setQuotes(r.quotes));
	});

	function refreshCards() {
		getMyCards().then((r) => setMyCards(r.cards));
	}

	function handleSuccessfulCardDialog() {
		popDialog();
		refreshCards();
	}

	function handleInviteFriend() {
		showDialog(<RecommendFriendDialog onSuccess={popDialog} />);
	}

	function initiatePointSale(barcode: string) {
		if (!myCards?.length) {
			showDialog(<AddCardDialog onComplete={handleSuccessfulCardDialog} />);
		} else {
			purchasePoint(barcode)
				.then(() => {
					setPurchaseSuccess(true);
					onSuccess?.();
				})
				.catch((message) => console.error(message));
		}
	}

	if (purchaseSuccess) {
		return (
			<StyledDialog title={t('pointPurchaseDialog.titleSuccess')}>
				<div dangerouslySetInnerHTML={{ __html: t('pointPurchaseDialog.success') }} />
				<Button $width={'full'} $variant={'outline'} onClick={popDialog}>
					{t('pointPurchaseDialog.close')}
				</Button>
			</StyledDialog>
		);
	}

	return (
		<StyledDialog title={t('pointPurchaseDialog.title')}>
			<div dangerouslySetInnerHTML={{ __html: t('pointPurchaseDialog.content') }} />
			{quotes?.map((quote) => (
				<Button $width={'full'} key={quote.barcode} onClick={() => initiatePointSale(quote.barcode)}>
					{t('buyPointFor', { count: quote.amount, price: formatCurrency(quote.price) })}
				</Button>
			))}
			<Button $width={'full'} $variant={'outline'} onClick={handleInviteFriend}>
				{t('inviteFriend')}
			</Button>
		</StyledDialog>
	);
};
