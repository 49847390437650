import React, { Suspense } from 'react';
import App from '@/App';
import * as serviceWorker from '@/serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import i18n from '@/locale/localization';
import moment from 'moment';
import 'moment/locale/et';
import { createRoot } from 'react-dom/client';

// if (process.env.NODE_ENV === 'production') {
//	Sentry.init({ dsn: 'https://8e382aad515649098344f5f4530f1105@sentry.io/1824092' });
// }

i18n.on('languageChanged', (lng) => {
	moment.locale(lng);
});

// TODO: Fix flickering on loading translation domains - suspense needs to wrap correct components
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter>
	<Suspense fallback={null}>
		<App />
	</Suspense>
</BrowserRouter>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
