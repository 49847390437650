import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CardFooter, CardHeader } from '@/components/Card/Card';
import { Button } from '@/components/Button';
import { InputField, SelectField } from '@/components/Input';
import { Formik, FormikProps } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { login } from '@/services/Auth';
import { AuthApi, Country, LookupApi } from '@smartswap/client-api';
import useAsyncEffect from 'use-async-effect';
import { config } from '@/configuration';
import * as Yup from 'yup';
import { AuthContext } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { screenSize } from '@/ScreenSize';
import { Logo } from '@/Icons';
import { PageCard, PageCardContent, CardLogoWrapper, PageCardWrapper } from '@/components/Card/PageCard';

interface RegisterFormProps {
	fullName: string;
	email: string;
	password: string;
	repeatPassword: string;
	country: string;
	invitation: string;
}

const CardWithLogo = styled(PageCard)`
	margin: 70px 0 28px;
`;

const RegisterButton = styled(Button)`
	margin-top: 18px;
`;

const RegisterFooter = styled(CardFooter)`
	color: #778699;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	width: 100%;
	padding: 16px 0;

	@media screen and ${screenSize.mobile} {
		position: fixed;
		bottom: 0;
	}
`;

const LoginNote = styled.div`
	font-family: 'InterUI';
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 80px;

	color: var(--login-color-note);
`;

function useRegisterValidationSchema() {
	const { t } = useTranslation('register');

	return Yup.object().shape({
		fullName: Yup.string()
			.min(2, t('form.validation.minChars', { minCount: 2 }))
			.max(50, t('form.validation.maxChars', { maxCount: 50 }))
			.matches(/.{2,} .{2,}/, t('form.validation.namesRequired'))
			.required(t('form.validation.nameRequired')),
		password: Yup.string()
			.min(8, t('form.validation.minChars', { minCount: 8 }))
			.matches(/[A-Z]/, t('form.validation.upperCaseRequired'))
			.matches(/[a-z]/, t('form.validation.lowerCaseRequired'))
			.matches(/[\d]/, t('form.validation.digitRequired'))
			.required(t('form.validation.passwordRequired')),
		repeatPassword: Yup.string()
			.required(t('form.validation.passwordConfirmationRequired'))
			.oneOf([Yup.ref('password')], t('form.validation.passwordMatchRequired')),
		email: Yup.string().email(t('form.validation.invalidEmail')).required(t('form.validation.emailRequired')),
		country: Yup.string().required(t('form.validation.countryRequired')),
	});
}

const defaultValues: RegisterFormProps = {
	fullName: '',
	password: '',
	repeatPassword: '',
	email: '',
	country: '',
	invitation: '',
};

const LoginForm = ({ handleSubmit, setFieldValue }: FormikProps<RegisterFormProps>) => {
	const [countries, setCountries] = useState<Country[]>([]);
	const lookupApi = new LookupApi(config);
	const { t } = useTranslation('register');

	useAsyncEffect(async () => {
		const [countryData, suggestion] = await Promise.all([
			lookupApi.lookupCountriesGet(),
			lookupApi.suggestCountryGet(),
		]);

		setCountries(countryData?.countries || []);
		if (suggestion.countryCode) {
			setFieldValue('country', suggestion.countryCode);
		}
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			<InputField type={'text'} name={'fullName'} label={t('form.fullName')} />
			<InputField type={'email'} name={'email'} label={t('form.email')} />
			<InputField type={'password'} name={'password'} label={t('form.password')} />
			<InputField type={'password'} name={'repeatPassword'} label={t('form.confirmPassword')} />
			<SelectField name={'country'} label={t('form.country')}>
				<option value={''} disabled={true}>
					{t('form.selectCountry')}
				</option>
				{countries.map((country) => (
					<option key={country.code} value={country.code}>
						{country.name}
					</option>
				))}
			</SelectField>

			<RegisterButton $width={'full'} type={'submit'}>
				{t('form.register')}
			</RegisterButton>
		</form>
	);
};

export const RegisterPage: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const invitation = query.get('invite') || '';
	const auth = useContext(AuthContext);
	const { t } = useTranslation('register');
	const registerValidationSchema = useRegisterValidationSchema();

	async function submitForm(values: RegisterFormProps) {
		const api = new AuthApi(config);
		await api.authRegisterPost({
			authRegisterRequest: {
				country: values.country,
				email: values.email,
				fullName: values.fullName,
				invitation: values.invitation,
				password: values.password,
			},
		});

		auth.login(await login(values.email, values.password));
		navigate('/', { replace: true });
	}

	const initialValues = { ...defaultValues, invitation };

	return (
		<PageCardWrapper>
			<CardWithLogo>
				<CardLogoWrapper>
					<Logo />
				</CardLogoWrapper>

				<CardHeader>{t('createAccount')}</CardHeader>
				<PageCardContent>
					<Formik
						validateOnBlur={true}
						validateOnChange={false}
						validationSchema={registerValidationSchema}
						initialValues={initialValues}
						onSubmit={submitForm}
						component={LoginForm}
					/>
				</PageCardContent>
				<RegisterFooter dangerouslySetInnerHTML={{ __html: t('agreementNotice') }} />
			</CardWithLogo>
			<LoginNote>
				Already have an account? <Link to={'/login'}>Log in</Link>
			</LoginNote>
		</PageCardWrapper>
	);
};
