import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { MemberAddress, PersonalApi } from '@smartswap/client-api';
import { config } from '@/configuration';
import { CloseIcon } from '@/Icons';
import { screenSize } from '@/ScreenSize';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 3em;
`;

interface AddressRowProps {
	className?: string;
	style?: CSSProperties;
	address: MemberAddress;
	onRemove?: (id: string) => void;
}

const Close = styled(CloseIcon)`
	cursor: pointer;
	margin-right: auto;
`;

const LeftSpan = styled.span`
  padding: 0 1em 0 0;
  flex: 0 0 auto;

  @media screen and ${screenSize.mobile} {
    padding: 0 5px;
    font-size: 0.8em;
  }
`;

const api = new PersonalApi(config);
// TODO: DELETE card
export const AddressRow: React.FC<AddressRowProps> = ({ address, className, style, onRemove }) => {
	async function handleRemove() {

		await api.myAddressesIdDelete({ id: address.id });
		onRemove && onRemove(address.id);
	}

	return (
		<Wrapper className={className} style={style}>
			<LeftSpan>{address.title}</LeftSpan>
			<Close onClick={handleRemove} />
		</Wrapper>
	);
};
