import { PersonalApi, Profile } from '@smartswap/client-api';
import { config } from '@/configuration';
import { getUserId } from './UserService';

const personalApi = new PersonalApi(config);

export async function getMyProfile() {
	if (!getUserId()) return {} as Profile;
	return await personalApi.myProfileGet();
}

export async function updateFullName(fullName: string) {
	return await personalApi.myFullNamePut({
		updateFullNameRequest: {
			fullName: fullName,
		},
	});
}

export async function updateCountry(countryCode: string) {
	return personalApi.myCountryPut({
		updateCountryRequest: {
			countryCode: countryCode,
		},
	});
}

export function updatePhone(newPhone: string) {
	return personalApi.myPhonePut({
		updatePhoneRequest: {
			newPhone,
		},
	});
}

export function verifyPhoneCode(code: string) {
	return personalApi.myPhoneConfirmPost({
		confirmPhoneRequest: {
			code,
		},
	});
}

export async function verifyEmail(verificationCode: string) {
	return personalApi.myEmailConfirmPost({
		confirmEmailRequest: {
			code: verificationCode,
		},
	});
}
