import { CreateOfferRequestGradeEnum } from '@smartswap/client-api';

interface GradeDetails {
	id: string;
	value: CreateOfferRequestGradeEnum;
	description: string;
}

export const ItemGradeDescriptions: GradeDetails[] = [
	{ id: 'A', value: CreateOfferRequestGradeEnum.A, description: 'new' },
	{ id: 'B', value: CreateOfferRequestGradeEnum.B, description: 'good' },
	{ id: 'C', value: CreateOfferRequestGradeEnum.C, description: 'used' },
	{ id: 'D', value: CreateOfferRequestGradeEnum.D, description: 'asIs' },
];

export function getGrade(grade: string): GradeDetails | null {
	return (
		ItemGradeDescriptions.find((item) => {
			return item.id === grade;
		}) || null
	);
}
