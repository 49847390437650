import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { marked } from 'marked';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const ContentArea = styled.div`
	width: 100%;
	max-width: 730px;

	font-family: InterUI, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #778699;
	margin-bottom: 80px;

	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;

		text-align: center;
		letter-spacing: -0.4px;
		color: #2c3f58;
		margin-bottom: 64px;
		margin-top: 80px;
	}

	h2 {
		font-family: InterUI, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #2c3f58;
		margin-bottom: 24px;
	}
`;

export const ContentPage: React.FC = () => {
	const [content, setContent] = useState<string>('');
	const routeMatch = useMatch('/c/:slug');
	const {
		i18n: { language },
	} = useTranslation();
	const slug = routeMatch?.params.slug;
	// const locale = i18n.language;

	useEffect(() => {
		if (!slug) return;

		fetch(`${process.env.PUBLIC_URL}/content/${language}/${slug}.md`)
			.then((r) => r.text())
			.then((a) => {
				setContent(marked(a));
			});
	}, [slug, language]);

	return (
		<Wrapper>
			<ContentArea dangerouslySetInnerHTML={{ __html: content }} />
		</Wrapper>
	);
};
