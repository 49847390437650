import useAsyncEffect from 'use-async-effect';
import { config } from '@/configuration';
import { MemberAddress, PersonalApi } from '@smartswap/client-api';
import { useState } from 'react';

const personalApi = new PersonalApi(config);

export function usePersonalAddresses() {
	const [addresses, setAddresses] = useState<MemberAddress[]>([]);

	async function update() {
		const addressesResponse = await personalApi.myAddressesGet();
		setAddresses(addressesResponse.addresses || []);
	}

	useAsyncEffect(update, []);

	function addAddress(address: MemberAddress) {
		return personalApi.myAddressesPost({ createAddressRequest: address });
	}

	return { addresses, addAddress, update };
}
