import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';
import { DefaultApi } from '@smartswap/client-api';
import { config } from '@/configuration';
import useAsyncEffect from 'use-async-effect';

const api = new DefaultApi(config);

export type PushEventHandler = () => void;

interface PushContextProps {
	handlers: Map<WebPushEvent, Set<PushEventHandler>>;
	addHandler: (eventType: WebPushEvent, handler: PushEventHandler) => void;
	removeHandler: (eventType: WebPushEvent, handler: PushEventHandler) => void;
}

export const PushContext = createContext<PushContextProps>({} as PushContextProps);

interface WebPushProps {
	onOpen?: () => void;
	onMessage?: (message: WebPushMessage) => void;
	onClose?: () => void;
}

//TODO: reconnect and use existing token if valid
function useWebPush({ onClose, onOpen, onMessage }: WebPushProps) {
	const ws = useRef<WebSocket | null>(null);

	const close = useCallback(() => {
		ws.current?.close();
		ws.current = null;
	}, []);

	const open = useCallback(
		(url: string) => {
			close();

			ws.current = new WebSocket(url);
			if (!ws.current) return;

			ws.current.onopen = () => onOpen?.();
			ws.current.onmessage = (message) => onMessage?.(JSON.parse(message.data));
			ws.current.onclose = () => onClose?.();
		},
		[close, onClose, onMessage, onOpen],
	);

	return { open, close };
}

type WebPushEvent = 'ChatMessageCreated' | 'ReminderCreated' | 'ProfileUpdated' | 'ReminderDismissed';

interface WebPushMessage {
	event: WebPushEvent;
	to: string;
	detail: any;
}

export const PushContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const handlers = useRef(new Map<WebPushEvent, Set<PushEventHandler>>());
	const auth = useContext(AuthContext);
	const isLoggedIn = auth.isLoggedIn();

	const addHandler = useCallback((eventType: WebPushEvent, handler: PushEventHandler) => {
		const currentValues = handlers.current.get(eventType) || new Set<PushEventHandler>();
		currentValues.add(handler);

		if (!handlers.current.has(eventType)) {
			handlers.current.set(eventType, currentValues);
		}
	}, []);

	const removeHandler = useCallback((eventType: WebPushEvent, handler: PushEventHandler) => {
		const currentValues = handlers.current.get(eventType);
		if (!currentValues) return;

		currentValues.delete(handler);
	}, []);

	const handleEvent = useCallback((message: WebPushMessage) => {
		const _handlers = handlers.current.get(message.event);
		_handlers?.forEach((handler) => handler());
	}, []);

	const { open, close } = useWebPush({
		// onOpen: dispatch, Not needed, as every subscriber has onMount fetch
		onMessage: handleEvent,
	});

	const tryWsConnection = useCallback(async () => {
		if (!isLoggedIn) {
			close();
			return;
		}

		const webpush = await api.webpushPost();
		const wsUrl = webpush.url;

		if (!wsUrl) return;

		open(wsUrl);
	}, [close, isLoggedIn, open]);

	useEffect(() => {
		return close;
	}, [close]);

	useAsyncEffect(tryWsConnection, [isLoggedIn]);

	return (
		<PushContext.Provider value={{ handlers: handlers.current, addHandler, removeHandler }}>
			{children}
		</PushContext.Provider>
	);
};
