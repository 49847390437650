import styled from 'styled-components';
import { FormInputWrapper } from '@/components/Forms/FormInputWrapper';

export const FormLabel = styled.label`
    display: block;
    font-size: 0.7em;

    ${FormInputWrapper} {
        margin-top: 0.5rem;
    }
`;
