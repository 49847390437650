import * as React from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Button } from './Button';

import { ReactComponent as SearchIcon } from '@/Icons/Basic/Search.svg';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

interface SearchBoxProps {
	className?: string;
}

const StyledSearchIcon = styled(SearchIcon)`
	path {
		fill: white;
	}
`;

const WrapperForm = styled.form`
	display: flex;
	flex-flow: row;
	width: 50%;

	& > * {
		margin: 0 3px;
	}
`;

interface SearchProps {
	query: string;
}

export const SearchBox: React.FC<SearchBoxProps> = ({ className }) => {
	const navigate = useNavigate();
	const { t } = useTranslation('search');

	const searchForm = useFormik<SearchProps>({
		initialValues: {
			query: '',
		},
		onSubmit: (values) => {
			const url = new URL('/offers', window.location.origin);
			if (values.query !== '') {
				url.searchParams.set('search', values.query);
			}
			navigate(`${url.pathname}${url.search}`);
		},
	});

	return (
		<WrapperForm className={className} onSubmit={searchForm.handleSubmit}>
			<Input
				name="query"
				value={searchForm.values.query}
				onChange={searchForm.handleChange}
				placeholder={t('searchItems')}
			/>
			<Button type="submit">
				<StyledSearchIcon />
			</Button>
		</WrapperForm>
	);
};
