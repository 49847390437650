import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from './Dialog';

export function VerifyEmailDialog({ email }: { email: string }) {
	const { t } = useTranslation('account');

	return (
		<Dialog title={t('verifyEmailDialog.title')}>
			<span dangerouslySetInnerHTML={{ __html: t('verifyEmailDialog.content', { email }) }} />
		</Dialog>
	);
}
