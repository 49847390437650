import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDropdown } from '@/components/FeedPage/Filters/FilterDropdown';
import { SearchGetSortEnum } from '@smartswap/client-api';

const sortBy = [
	SearchGetSortEnum.IdDesc,
	SearchGetSortEnum.IdAsc,
	SearchGetSortEnum.UpvoteDesc,
	SearchGetSortEnum.UpvoteAsc,
];

interface SortDropdownProps {
	onSelect?: (sortBy: SearchGetSortEnum) => void;
	currentSort: SearchGetSortEnum;
}

export function OfferSortDropdown({ onSelect, currentSort }: SortDropdownProps) {
	const { t } = useTranslation('common');

	return (
		<FilterDropdown
			items={sortBy}
			activeItem={currentSort}
			title={t('sortBy')}
			onSelect={onSelect}
			itemText={(sortItem) => t(`sort.${sortItem}`)}
		/>
	);
}
