import { Button } from '@/components/Button';
import * as React from 'react';
import { useCallback } from 'react';
import { CardHeader, InnerCardContent, Section, Title } from '@/pages/ProfileComponents';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@/contexts/DialogContext';
import { Dialog } from '@/components/Dialog';
import { Row } from '@/components/Grid';
import styled from 'styled-components';
import { personalApi } from '@/services/api';
import { useAuthContext } from '@/contexts/AuthContext';

const InnerCard = styled(InnerCardContent)`
	font-weight: 400;
`;

// function UserDeletionSuccessDialog() {
// 	const { t } = useTranslation('account');
// 	return (
// 		<Dialog title={t('deleteUser.title')}>
// 			<span dangerouslySetInnerHTML={{ __html: t('deleteUser.deletionSuccessful') }} />
// 		</Dialog>
// 	);
// }

function UserDeletionFailedDialog() {
	const { t } = useTranslation('account');
	return (
		<Dialog title={t('deleteUser.title')}>
			<span dangerouslySetInnerHTML={{ __html: t('deleteUser.deletionFailed') }} />
		</Dialog>
	);
}

function ConfirmationDialog() {
	const { t } = useTranslation('account');
	const auth = useAuthContext();
	const { popDialog, showDialog, closeAll } = useDialog();

	const onDeleteUser = useCallback(() => {
		personalApi
			.myProfileDelete()
			.then(() => {
				auth.logout();
				closeAll();
				//TODO: Show notification
			})
			.catch(() => {
				showDialog(<UserDeletionFailedDialog />);
			});
	}, [auth, closeAll, showDialog]);

	return (
		<Dialog title={t('deleteUser.title')}>
			{t('deleteUser.content')}

			<Row style={{ marginTop: '16px' }}>
				<Button onClick={onDeleteUser} $color={'danger'}>
					{t('deleteUser.delete')}
				</Button>
				<Button $variant={'outline'} onClick={popDialog}>
					{t('deleteUser.cancel')}
				</Button>
			</Row>
		</Dialog>
	);
}

export function DeleteUserSection() {
	const { t } = useTranslation('account');
	const { showDialog } = useDialog();

	return (
		<Section>
			<InnerCard>
				<CardHeader>
					<Title>{t('deleteUser.title')}</Title>
					<Button onClick={() => showDialog(<ConfirmationDialog />)}>{t('deleteUser.delete')}</Button>
				</CardHeader>
				{t('deleteUser.description')}
			</InnerCard>
		</Section>
	);
}
