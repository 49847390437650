import { config, getUserFromStorage } from '@/configuration';

export function uploadMedia(blob: Blob, contentType: string) {
	const formData = new FormData();
	formData.append('Content-Type', contentType); // xxx: have faith
	formData.append('file', blob);

	const apiKey = getUserFromStorage()?.accessToken;

	return fetch(config.basePath + '/media/upload', {
		body: formData,
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + apiKey,
		},
	});
}

export async function uploadImage(blob: Blob) {
	return await uploadMedia(blob, 'image/jpeg');
}
