import { DropdownList, DropdownListItem, DropdownSelector, LightDropdown } from '@/components/Dropdown';
import React from 'react';

interface FilterDropdownProps<Item> {
	title?: string;
	items: Item[];
	activeItem: Item;
	onSelect?: (item: Item) => void;
	itemText?: (item: Item) => string;
}

export function FilterDropdown<Item>({ title, items, activeItem, onSelect, itemText }: FilterDropdownProps<Item>) {
	return (
		<LightDropdown
			content={
				<DropdownList>
					{items.map((item, index) => {
						return (
							<DropdownListItem
								key={index}
								$active={item === activeItem}
								onClick={() => onSelect?.(item)}
							>
								{itemText?.(item)}
							</DropdownListItem>
						);
					})}
				</DropdownList>
			}
		>
			<label>{title}</label>
			<DropdownSelector>{itemText?.(activeItem)}</DropdownSelector>
		</LightDropdown>
	);
}
