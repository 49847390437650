import * as React from 'react';
import { CSSProperties, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ExitIcon, HeartIcon, ListIcon, SettingsIcon } from '../Icons';
import { FeaturesContext } from '@/contexts/FeaturesContext';
import { useAuthContext } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const ProfilePop = styled.div`
	color: #2c3f58;
	font-size: 14px;
	font-weight: 300;
`;

const ListItem = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 8px 16px;
	box-sizing: border-box;

	&:hover {
		background-color: rgba(240, 244, 248, 1);
	}
`;

const profileItems = [
	{
		linkTo: '/profile/giveaway',
		title: 'itemsTrades',
		icon: <ListIcon />,
	},
	{
		linkTo: '/profile/wishlist',
		title: 'wishlist',
		icon: <HeartIcon />,
	},
	{
		linkTo: '/profile/settings',
		title: 'accountSettings',
		icon: <SettingsIcon />,
	},
];

const Text = styled.span`
	margin-left: 14px;
`;

const separatorStyle: CSSProperties = {
	borderTop: '1px solid #D7E1EA',
};

export const ProfileDropdown: React.FC = () => {
	const features = useContext(FeaturesContext);
	const auth = useAuthContext();
	const { t } = useTranslation('account');

	function logOut() {
		auth.logout();
	}

	const nav = [...profileItems];
	if (!features.wishlist) {
		for (let i = 0; i < nav.length; i++) {
			if (nav[i].title === 'wishlist') {
				nav.splice(i, 1);
				break;
			}
		}
	}

	return (
		<ProfilePop>
			{nav.map((item) => (
				<NavLink key={item.title} to={item.linkTo}>
					<ListItem>
						{!!item.icon && item.icon}
						<Text>{t(item.title)}</Text>
					</ListItem>
				</NavLink>
			))}

			<ListItem style={separatorStyle} onClick={logOut}>
				<ExitIcon />
				<Text>{t('logout')}</Text>
			</ListItem>
		</ProfilePop>
	);
};
