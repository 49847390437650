import styled from 'styled-components';
import { Button } from '@/components/Button';
import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { Input, Select } from '@/components/Input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/semantic-ui.css';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '@/components/Forms/FormLabel';

import { FormInputWrapper } from '@/components/Forms/FormInputWrapper';

const SelfContainedFormInputRow = styled.div`
	${Button} {
		min-height: 32px;
		height: 32px;
	}
`;

const ErrorMessage = styled.div`
	font-size: 0.7rem;
	margin-top: 0.5rem;
	color: var(--color-error);
`;

type SelfContainedInputProps = {
	label: string; // form field label
	name: string; // form field name
	type?: string; // form field type
	initialValue?: string; // value from server
	value?: string; // value provided by user
	onSubmit?: (value: string) => void;
	readonly?: boolean;
	submitText?: string;
	subtext?: string;
	error?: string;
};
export const SelfContainedInput: React.FC<SelfContainedInputProps> = (props) => {
	const [value, setValue] = useState<string | undefined>(props.initialValue);
	const unsaved = value !== props.initialValue;
	const { t } = useTranslation('common');

	return (
		<SelfContainedFormInputRow>
			<FormLabel>
				<span>{props.label}</span>
				{props.subtext && <p style={{ fontSize: '.6em' }}>{props.subtext}</p>}
				<FormInputWrapper>
					{props.type === 'phone' && (
						<PhoneInput
							value={value}
							dropdownStyle={{ borderRadius: 'var(--border-radius 0 0 var(--border-radius)' }}
							containerStyle={{ width: '100%' }}
							country={'ee'}
							onChange={setValue}
						/>
					)}
					{props.type !== 'phone' && (
						<Input
							type={props.type || 'text'}
							name={props.name}
							value={value || ''}
							readOnly={props.readonly || false}
							onChange={(e: any) => setValue(e.target.value)}
						/>
					)}
					{unsaved && (
						<Button type={'submit'} onClick={() => props.onSubmit && props.onSubmit(value || '')}>
							{props.submitText || t('update')}
						</Button>
					)}
				</FormInputWrapper>
			</FormLabel>
			{!!props.error && <ErrorMessage>{props.error}</ErrorMessage>}
		</SelfContainedFormInputRow>
	);
};

type SelfContainedSelectProps = {
	label: string; // form field label
	name: string; // form field name
	type?: string; // form field type
	initialValue?: string; // value from server
	value?: string; // value provided by user
	onSubmit?: (value: string) => void;
	submitText?: string;
};

export const SelfContainedSelect: React.FC<PropsWithChildren<SelfContainedSelectProps>> = (props) => {
	const [value, setValue] = useState<string | undefined>(props.initialValue);
	const unsaved = value !== props.initialValue;
	const { t } = useTranslation('common');

	return (
		<SelfContainedFormInputRow>
			<FormLabel><span>{props.label}</span>
				<FormInputWrapper>
					<Select name={props.name} value={value || ''} onChange={(e: any) => setValue(e.target.value)}>
						{props.children}
					</Select>
					{unsaved && (
						<Button type={'submit'} onClick={(_: any) => props.onSubmit && props.onSubmit(value || '')}>
							{props.submitText || t('update')}
						</Button>
					)}
				</FormInputWrapper>
			</FormLabel>
		</SelfContainedFormInputRow>
	);
};
