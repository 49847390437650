import React from 'react';
import { Dialog } from './Dialog';
import { addFriendEmail } from '@/services/DefaultService';
import { SelfContainedInput } from './Forms/SelfContainedInput';

interface RecommendFriendForm {
	onSuccess?: () => void;
}

export const RecommendFriendDialog: React.FC<RecommendFriendForm> = ({ onSuccess }) => {
	function onSubmit(email: string) {
		addFriendEmail({ email }).then(() => onSuccess?.());
	}

	return (
		<Dialog title={'Kutsu sõber taaskasutajaks'}>
			<p>
				Täname sind, et soovid aidata kaasa taaskasutusele ja taaskasutajate arvu kasvule!
				<br />
				Punkti kanname sinu kontole siis kui sinu sõber registreerib end Smartswapi kasutajaks. Mida rohkem sinu
				sõpru end kasutajaks registreerib seda rohkem punkte teenid!
			</p>
			<SelfContainedInput
				label="Sõbra e-posti aadress"
				name="email"
				initialValue={''}
				onSubmit={onSubmit}
				submitText={'Saada'}
			/>
		</Dialog>
	);
};
