import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { SidebarItem } from './SidebarItem';
import { NavLink, Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { screenSize } from '@/ScreenSize';
import { DialogRoot } from '@/contexts/DialogContext';
import { Header } from './Header';
import { CategoriesList } from './Categories/CategoriesList';
import { Footer } from './Footer';
import { UserList } from './DirectMessages/UserList';
import { useTranslation } from 'react-i18next';
import { List, Settings } from 'react-feather';

const Wrapper = styled.div<{ $sidebar: boolean }>`
	background: rgba(240, 244, 248, 1);
	min-height: 100%;
	width: 100%;

	display: grid;
	grid-gap: 1px;

	grid-template:
		'header' auto
		'content' 1fr
		'footer' auto / 100%;

	@media screen and ${screenSize.sm} {
		grid-template:
			'header header' 60px
			'${(p) => (p.$sidebar ? 'sidebar' : 'content')} content' auto
			'footer footer' auto / auto 1fr;
	}
`;

const HeaderArea = styled.div`
	grid-area: header;
`;

const Sidebar = styled.div`
	grid-area: sidebar;
	height: 100%;
	width: 300px;
	background: rgba(255, 255, 255, 1);
	display: none;
	min-height: 50vh;

	@media ${screenSize.sm} {
		display: initial;
	}

	@media screen and ${screenSize.tablet} {
		width: 200px;
		font-size: 80%;
	}
`;

const SidebarGroup = styled.div`
	padding: 8px 0;
	border-bottom: 1px solid rgba(215, 225, 234, 1);

	&:last-of-type {
		border-bottom: none;
	}
`;

const Content = styled.div`
	position: relative;
	box-sizing: border-box;
	grid-area: content;
	width: 100%;
	overflow: hidden;
	min-height: 50vh;

	@media screen and ${screenSize.md} {
		margin: 0 auto;
		//TODO: redo layout
		// Disabled as homepage needs full width
		//max-width: 1024px;
	}

	& > * {
		padding: 16px 8px;
	}

	@media screen and ${screenSize.sm} {
		& > * {
			padding: 16px 16px;
		}
	}
`;

const Icon = styled.div`
	width: 16px;
	height: 16px;
	margin-right: 12px;

	svg {
		stroke-width: 2px;

		width: 100%;
		height: 100%;
		stroke: var(--sidebar-item-icon-color);

		a.active & {
			stroke: var(--sidebar-item-icon-color-active);
		}
	}
`;

const accountNavigationItems: NavItem[] = [
	{
		title: 'itemsTrades',
		path: '/profile/giveaway',
		icon: <List strokeWidth={1.5} />,
	},
	// {
	// 	title: 'wishlist',
	// 	path: '/profile/wishlist',
	// },
	{
		title: 'accountSettings',
		path: '/profile/settings',
		icon: <Settings strokeWidth={1.5} />,
	},
];

interface NavItemsProps {
	items: NavItem[];
}

const NavItems = ({ items }: PropsWithChildren<NavItemsProps>) => {
	return (
		<>
			{items.map((navItem) => (
				<NavLink to={navItem.path} key={navItem.path}>
					<SidebarItem>
						{navItem.icon && <Icon>{navItem.icon}</Icon>}
						{navItem.title}
					</SidebarItem>
				</NavLink>
			))}
		</>
	);
};

interface NavItem {
	title: string;
	path: string;
	icon?: ReactNode;
}

interface AccountSidebarProps {
	paths: NavItem[];
}

const AccountSidebar: React.FC<AccountSidebarProps> = ({ paths }) => {
	return (
		<React.Fragment>
			<SidebarGroup>
				<NavItems items={paths} />
			</SidebarGroup>
		</React.Fragment>
	);
};

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const messageRoute = useMatch('/messages/*');
	const showSidebar =
		!location.pathname.startsWith('/item/') &&
		!location.pathname.startsWith('/c/') &&
		!location.pathname.startsWith('/minimalism');
	const { t } = useTranslation('account');

	const profileSidenav = [...accountNavigationItems];

	// TODO: Refactor with previous features check
	const sideNavTranslated = profileSidenav.map((item) => {
		return { ...item, title: t(item.title) };
	});

	return (
		<Wrapper $sidebar={showSidebar}>
			<DialogRoot />

			<HeaderArea>
				<Header />
			</HeaderArea>

			{showSidebar && (
				<Sidebar>
					<Routes>
						<Route path={'/profile/*'} element={<AccountSidebar paths={sideNavTranslated} />} />
						<Route path={'/messages/*'} element={<UserList />} />
						<Route path={'/*'} element={<CategoriesList />} />
					</Routes>
				</Sidebar>
			)}
			<Content>{children}</Content>
			{!messageRoute && <Footer />}
		</Wrapper>
	);
};
