import React, { ChangeEvent, useCallback } from 'react';
import { MyActivityGetStateEnum } from '@smartswap/client-api';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Select } from '@/components/Input';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { screenSize } from '@/ScreenSize';

const Wrapper = styled.div`
	display: flex;
	align-items: flex-end;
	border-bottom: 1px solid var(--filter-tabs-color-border);
	padding: 0;
	width: fit-content;
	flex: 0;
	//margin-left: calc(var(--step) * 2);

	& > * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}

	* {
		box-sizing: border-box;
	}
`;

interface TabButtonProps {
	$active?: boolean;
}

const activeTabStyle = css`
	border-bottom: 2px solid var(--filter-tabs-color-active);
	color: var(--filter-tabs-color-active);
`;

const TabButton = styled.div<TabButtonProps>`
	background: none;
	border: none;
	color: var(--filter-tabs-color-text);
	padding-bottom: 8px;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: -1px;
	border-bottom: 2px solid transparent;

	font-family: 'InterUI';
	font-size: 14px;
	font-weight: 600;

	&:hover {
		${activeTabStyle}
	}

	${(p) => p.$active && activeTabStyle}
`;

const ALL_FILTER_KEY = 'all';
const FILTER_KEY = 'filter';

const groups: string[] = [
	ALL_FILTER_KEY,
	MyActivityGetStateEnum.Draft,
	MyActivityGetStateEnum.Reserved,
	MyActivityGetStateEnum.Published,
	MyActivityGetStateEnum.Traded,
];

const FilterSelect = styled(Select)`
	background: white;
`;

function getFilterPath(filter: string) {
	return filter === ALL_FILTER_KEY ? '' : `?${FILTER_KEY}=${filter}`;
}

export function FilterTabs() {
	const [params] = useSearchParams();
	const filter = params.get(FILTER_KEY) || ALL_FILTER_KEY;
	const isDesk = useMediaQuery(`screen and ${screenSize.sm}`);

	return (
		// div wrapper because of stupid wrapper rule
		<div>
			{isDesk ? (
				<DesktopFilter activeFilter={filter} groups={groups} />
			) : (
				<MobileFilter activeFilter={filter} groups={groups} />
			)}
		</div>
	);
}

interface FilterSelectorProps {
	groups: string[];
	activeFilter: string;
}

const MobileFilter = ({ activeFilter, groups }: FilterSelectorProps) => {
	const { t } = useTranslation('my-items');
	const navigate = useNavigate();

	const handleOptionsChange = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
		const group = ev.target.value;
		navigate(getFilterPath(group));
	}, []);

	return (
		<FilterSelect onChange={handleOptionsChange}>
			{groups.map((group) => {
				return (
					<option key={group} selected={group === activeFilter} value={group}>
						{capitalize(t(`offerStates.${group}`))}
					</option>
				);
			})}
		</FilterSelect>
	);
};

const DesktopFilter = ({ activeFilter, groups }: FilterSelectorProps) => {
	const { t } = useTranslation('my-items');

	return (
		<Wrapper>
			{groups.map((group) => {
				return (
					<Link to={getFilterPath(group)} key={group}>
						<TabButton $active={group === activeFilter}>{capitalize(t(`offerStates.${group}`))}</TabButton>
					</Link>
				);
			})}
		</Wrapper>
	);
};
